var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('b-container',{class:['main-header bg-white d-flex flex-column',
           { 'shadows-line-down': _vm.isUnifyExhibitorPortal && _vm.authUserHasCompany },
           { 'shadows-md': _vm.fixed && !_vm.isUnifyExhibitorPortal }],attrs:{"fluid":""}},[(!_vm.toggleGlobalSearch)?[_c('header',{staticClass:"ml-0 order-1"},[(_vm.isDesktop)?_c('div',{staticClass:"navbar-container w-100 px-4 py-2 d-flex align-items-center"},[(!_vm.isUnifyExhibitorPortal)?_c('b-link',{staticClass:"main-logo",attrs:{"to":{ path: '/' }}},[_c('avatar-solo-widget',{attrs:{"square":true,"src":_vm.logoPath,"size":"56"}})],1):_vm._e(),(_vm.isUnifyExhibitorPortal && _vm.unifyDomain && _vm.authUserHasCompany && _vm.isEnabledUnifyLogin)?_c('b-link',{staticClass:"btn-back-to-unify d-flex align-items-center gap-2 py-2 pl-3 pr-20 border border-neutral-n-4-line\n          rounded-pill text-decoration-none text-neutral-n-8-dark-ink",attrs:{"href":_vm.unifyPortalUrl,"target":"_blank"}},[_c('font-awesome-component',{attrs:{"container-classes":"icon-20","icon":"far fa-arrow-left","icon-classes":"fa-sm"}}),_c('avatar-solo-widget',{attrs:{"src":_vm.logoPath,"size":"24"}}),_c('span',{staticClass:"button small font-weight-bold text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('exhibitor-portal.back-to-event'))+" ")])],1):_vm._e(),(_vm.isAuthenticated && !_vm.isUnifyExhibitorPortal)?_c('navbar-component',{attrs:{"menus":_vm.mainMenuList}}):_vm._e(),(_vm.isAuthenticated)?_c('div',{staticClass:"align-items-center ml-auto d-flex column-gap-4"},[(_vm.featureByKey(_vm.FeatureKeys.GLOBAL_SEARCH)
              && _vm.featureByKey(_vm.FeatureKeys.GLOBAL_SEARCH).enabled
              && !_vm.isUnifyExhibitorPortal)?_c('div',{staticClass:"d-flex align-items-center justify-content-end w-100",staticStyle:{"min-width":"8rem"},attrs:{"id":"global-search-input"}},[_c('input-search-component',{staticClass:"w-100",staticStyle:{"max-width":"12.25rem"},attrs:{"default-value":_vm.searchQuery,"placeholder":_vm.$t('app.home-page.search-placeholder'),"color":"gray","size":"md"},on:{"search-query-enter-key":_vm.onSearchEnterKey}})],1):_vm._e(),(_vm.featureByKey(_vm.FeatureKeys.NOTIFICATIONS)
              && _vm.featureByKey(_vm.FeatureKeys.NOTIFICATIONS).enabled
              && (!_vm.isUnifyExhibitorPortal || (_vm.isUnifyExhibitorPortal && _vm.authUserHasCompany)))?_c('button-icon-component',{class:{'bg-blue-b-1-light-blue notification-button': !_vm.isNotificationClosed},attrs:{"size":"md","variant":"alt-1"},on:{"on-click":_vm.toggleNotificationsModal},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('span',{staticClass:"fa-layers icon-24"},[_c('font-awesome-component',{attrs:{"icon-classes":['icon-24', _vm.isNotificationClosed ?
                    'text-neutral-n-8-dark-ink' : 'text-blue-b-3-primary-blue' ],"icon":"fa-regular fa-bell"}}),(_vm.unreadNotifications > 0 )?_c('label',{staticClass:"fa-layers-counter bg-red-r-3-primary-red mt-n1 mr-n2 p-3 count-pill"},[_c('span',[_vm._v(_vm._s(_vm.unreadNotifications > 99 ? '99+' : _vm.unreadNotifications))])]):_vm._e()],1)]},proxy:true}],null,false,2375137841)}):_vm._e(),(_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE)
              && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE).enabled
              && _vm.isUnifyExhibitorPortal
              && _vm.companyId)?_c('button-icon-component',{attrs:{"size":"md","variant":"alt-1"},on:{"on-click":function($event){return _vm.$router.push({
              name: 'company-cms-cart',
              params: { companyId: _vm.companyId },
            })}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('span',{staticClass:"fa-layers icon-24"},[_c('font-awesome-component',{attrs:{"icon":"fa-regular fa-cart-shopping","icon-classes":"icon-24 text-neutral-n-8-dark-ink"}}),(_vm.numberOfItemInCart > 0 )?_c('label',{staticClass:"fa-layers-counter bg-red-r-3-primary-red mt-n1 mr-n2 p-3 count-pill"},[_c('span',[_vm._v(_vm._s(_vm.numberOfItemInCart > 99 ? '99+' : _vm.numberOfItemInCart))])]):_vm._e()],1)]},proxy:true}],null,false,406441259)}):_vm._e(),_c('locale-switcher',{on:{"on-open-menu":_vm.closeMenuMobile}}),(_vm.pageType !== 'sign-up'
              && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_EXTERNAL_REGISTRATION_FEATURE)
              && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_EXTERNAL_REGISTRATION_FEATURE).enabled
              && _vm.community.organizer.registrationWebsite)?_c('button-component',{staticClass:"mr-2 btn-external-registration",attrs:{"text":("" + (_vm.$t('app.login-page.register'))),"size":"md","variant":"secondary"},on:{"on-click":_vm.onRegisterClick}}):_vm._e(),_c('div',[_c('avatar-solo-widget',_vm._b({staticClass:"user-avatar",attrs:{"firstname":_vm.authUser.firstName,"lastname":_vm.authUser.lastName,"src":_vm.authUser.profilePicture,"with-borders":!_vm.authUser.profilePicture,"size":"40"},on:{"on-click":_vm.onAvatarClick}},'avatar-solo-widget',_vm.useTestDataAttribute('auth-user-avatar'),false)),_c('transition',{attrs:{"mode":"out-in","name":"fade"}},[(_vm.isMenuOpen)?_c('my-profile-menu-component',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onAvatarClick),expression:"onAvatarClick"}],staticClass:"mr-40",on:{"close-profile-menu":function($event){_vm.isMenuOpen = false}}}):_vm._e()],1)],1)],1):_c('unauthenticated-header-view',{on:{"toggle-global-search":_vm.toggleGlobalSearch}})],1):_c('div',{staticClass:"gap-1 p-1 navbar-container-mobile d-flex align-items-center\n          justify-content-between"},[_c('div',{staticClass:"menu-dropdown d-flex align-items-center"},[(_vm.isAuthenticated && (!_vm.isUnifyExhibitorPortal || (_vm.isUnifyExhibitorPortal && _vm.authUserHasCompany)))?_c('navbar-component',{ref:"navbar-component-mobile",attrs:{"menus":_vm.mainMenuList}}):_vm._e(),(!_vm.isAuthenticated && _vm.isDesktop)?_c('b-link',{attrs:{"to":{ path: '/' }}},[_c('avatar-solo-widget',{attrs:{"square":true,"src":_vm.logoPath,"avatar-size":"56"}})],1):_vm._e()],1),(_vm.isAuthenticated)?[_c('div',{staticClass:"d-flex align-items-center gap-1 w-100",class:_vm.showMenuItems ? 'justify-content-around' : 'justify-content-end'},[(_vm.featureByKey(_vm.FeatureKeys.GLOBAL_SEARCH)
                && _vm.featureByKey(_vm.FeatureKeys.GLOBAL_SEARCH).enabled
                && !_vm.isUnifyExhibitorPortal)?_c('button-icon-component',{attrs:{"size":"md","variant":"alt-1"},on:{"on-click":_vm.toggleSearch},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-magnifying-glass","icon-classes":"text-neutral-n-8-dark-ink"}})]},proxy:true}],null,false,554226718)}):_vm._e(),(_vm.featureByKey(_vm.FeatureKeys.NOTIFICATIONS) &&
                _vm.featureByKey(_vm.FeatureKeys.NOTIFICATIONS).enabled &&
                (!_vm.isUnifyExhibitorPortal || (_vm.isUnifyExhibitorPortal && _vm.authUserHasCompany)))?_c('button-icon-component',{attrs:{"size":"md","variant":"alt-1"},on:{"on-click":_vm.toggleNotificationsModal},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('span',{staticClass:"fa-layers icon-24"},[_c('font-awesome-component',{attrs:{"icon-classes":['icon-16', _vm.isNotificationClosed ?
                      'text-neutral-n-8-dark-ink' : 'text-blue-b-3-primary-blue'],"icon":"fa-regular fa-bell"}}),(_vm.unreadNotifications > 0 )?_c('div',{staticClass:"bg-red-r-3-primary-red count-pill fa-layers-counter mr-n1 mt-n1 mt-n2"},[_c('span',[_vm._v(_vm._s(_vm.unreadNotifications > 99 ? '99+' : _vm.unreadNotifications))])]):_vm._e()],1)]},proxy:true}],null,false,3043138478)}):_vm._e(),(_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE)
                && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_SALES_SERVICE_FEATURE).enabled
                && _vm.isUnifyExhibitorPortal
                && _vm.companyId
                && _vm.authUserHasCompany)?_c('button-icon-component',{attrs:{"size":"md","variant":"alt-1"},on:{"on-click":function($event){return _vm.$router.push({
                name: 'company-cms-cart',
                params: { companyId: _vm.companyId },
              })}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('span',{staticClass:"fa-layers icon-24"},[_c('font-awesome-component',{attrs:{"icon":"fa-regular fa-cart-shopping","icon-classes":"icon-16 text-neutral-n-8-dark-ink"}}),(_vm.numberOfItemInCart > 0 )?_c('label',{staticClass:"fa-layers-counter bg-red-r-3-primary-red mt-n1 mr-n2 p-3 count-pill"},[_c('span',[_vm._v(_vm._s(_vm.numberOfItemInCart > 99 ? '99+' : _vm.numberOfItemInCart))])]):_vm._e()],1)]},proxy:true}],null,false,4203921418)}):_vm._e(),(_vm.isChatPanelFeatureEnabled && _vm.authUserHasCompany)?_c('button-icon-component',{staticClass:"position-relative",attrs:{"size":"md","variant":"alt-1"},on:{"on-click":_vm.onToggleMessageBox},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.unReadCount > 0)?_c('div',{staticClass:"fa-layers-counter bg-red-r-3-primary-red rounded-pill count-pill"},[_c('span',[_vm._v(" "+_vm._s(_vm.unReadCount > 99 ? '99+' : _vm.unReadCount)+" ")])]):_vm._e(),_c('font-awesome-component',{attrs:{"icon-classes":[ _vm.isMessageBoxClose ?
                    'text-neutral-n-8-dark-ink' : 'text-blue-b-3-blue-primary'],"container-classes":"icon-24","icon":"fa-regular fa-comment-dots"}})]},proxy:true}],null,false,1045862491)}):_vm._e()],1),(_vm.pageType !== 'sign-up'
              && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_EXTERNAL_REGISTRATION_FEATURE)
              && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_EXTERNAL_REGISTRATION_FEATURE).enabled
              && _vm.community.organizer.registrationWebsite)?_c('button-component',{staticClass:"btn-external-registration",attrs:{"size":_vm.isMobile ? 'sm' : 'md',"text":("" + (_vm.$t('app.login-page.register'))),"variant":"secondary"},on:{"on-click":_vm.onRegisterClick}}):_vm._e(),_c('div',{staticClass:"pl-2"},[_c('avatar-solo-widget',_vm._b({staticClass:"user-avatar",attrs:{"firstname":_vm.authUser.firstName,"lastname":_vm.authUser.lastName,"src":_vm.authUser.profilePicture,"with-borders":!_vm.authUser.profilePicture,"size":"40"},on:{"on-click":_vm.onAvatarClick}},'avatar-solo-widget',_vm.useTestDataAttribute('auth-user-avatar'),false)),(_vm.isMenuOpen)?_c('my-profile-menu-component',{on:{"close-profile-menu":function($event){return _vm.onToggleProfileMenu(false)}}}):_vm._e()],1)]:_vm._e(),(!_vm.isAuthenticated)?_c('unauthenticated-header-view',{attrs:{"self-context":"AuthenticationStore"},on:{"toggle-search":_vm.toggleSearch}}):_vm._e()],2)])]:_c('global-input-search-component',{attrs:{"default-value":""},on:{"search-query-enter-key":_vm.onSearchEnterKey,"cancel-search":_vm.cancelSearch}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }