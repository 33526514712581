








































































































































































import { Component, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import NavbarComponent from '@/components/navbar/NavbarComponent.vue';
import MenuModel from '@/models/MenuModel';
import MenuType from '@/utils/enums/MenuType';
import Community from '@/models/graphql/Community';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import VueContextLoading from '@/components/VueContextLoading';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import GlobalInputSearchComponent from '@/components/GlobalInputSearchComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { mixins } from 'vue-class-component';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import RouteHelper from '@/utils/helpers/RouteHelper';

@Component({
  methods: { useTestDataAttribute },
  components: {
    AvatarSoloWidget,
    FontAwesomeComponent,
    GlobalInputSearchComponent,
    LocaleSwitcher,
    ButtonComponent,
    ButtonIconComponent,
    NavbarComponent,
  },
})
export default class UnauthenticatedHeaderView extends mixins(BreakpointWrapper, VueContextLoading) {
  @Getter
  protected menuByType!: (menuType: MenuType) => MenuModel[];

  @Getter
  protected community!: Community;

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Getter
  protected isUnifyExhibitorPortal!: boolean;

  @State
  protected unifyDomain!: string;

  protected FeatureKeys = FeatureKeys;

  protected toggleGlobalSearch = false;

  protected searchQuery = '';

  private FileResourceHelper = FileResourceHelper;

  get pageType(): string {
    return this.$route.name ?? '';
  }

  protected get mainMenuList(): MenuModel[] {
    return this.menuByType(MenuType.MAIN);
  }

  protected get logoPath(): string {
    if (this.community?.logoFileResource?.path) {
      return FileResourceHelper.getFullPath(this.community?.logoFileResource, 'w100') !== ''
        ? FileResourceHelper.getFullPath(this.community?.logoFileResource, 'w100')
        : '';
    }
    return this.community?.logoFileResource?.path || '';
  }

  protected get unifyPortalUrl(): string {
    return RouteHelper.externalNavigationBetweenPortals(this.unifyDomain, 'home');
  }

  @Watch('$route', {
    immediate: true,
    deep: true,
  })
  onRouteChange(): void {
    this.cancelSearch();
  }

  protected toggleSearch(): void {
    this.toggleGlobalSearch = !this.toggleGlobalSearch;
  }

  protected onSearchEnterKey(payload: { query: string }): void {
    if (payload.query) {
      this.searchQuery = payload.query;
      this.$router.push({
        ...this.$router.resolve({ name: 'global-search' }).location,
        ...{ query: { search: this.searchQuery } },
      })
        .finally(() => {
          this.cancelSearch();
          this.searchQuery = '';
          if (this.$refs['global-search-input']) {
            ((this.$refs['global-search-input'] as InputSearchComponent).$el.children[1] as HTMLElement).blur();
          }
        });
    }
  }

  protected cancelSearch(): void {
    this.toggleGlobalSearch = false;
  }

  private closeMenuMobile(): void {
    if (this.$refs['navbar-component-mobile']) {
      (this.$refs['navbar-component-mobile'] as NavbarComponent).toggleHamburgerMenu = false;
    }
  }

  private onSignInClick(): void {
    this.$router.push({ name: 'sign-in' });
  }

  private onSignupClick(): void {
    this.$router.push({ name: 'sign-up' });
  }

  private onRegisterClick(): void {
    window.open(this.community.organizer?.registrationWebsite || '/', '_blank');
  }
}
