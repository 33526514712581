var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100 unauthenticated-header p-2"},[(!_vm.toggleGlobalSearch)?[_c('div',{staticClass:"d-flex align-items-center"},[(!_vm.isUnifyExhibitorPortal)?_c('navbar-component',{ref:"navbar-component-mobile",attrs:{"menus":_vm.mainMenuList}}):_vm._e(),(_vm.isUnifyExhibitorPortal && _vm.unifyDomain && !_vm.isDesktop)?_c('b-link',{staticClass:"btn-back-to-unify d-flex align-items-center gap-2 py-2 pl-3 pr-20 border border-neutral-n-4-line\n          rounded-pill text-decoration-none text-neutral-n-8-dark-ink",attrs:{"href":_vm.unifyPortalUrl,"target":"_blank"}},[_c('font-awesome-component',{attrs:{"container-classes":"icon-20","icon":"far fa-arrow-left","icon-classes":"fa-sm"}}),_c('avatar-solo-widget',{attrs:{"src":_vm.logoPath,"size":"24"}}),(_vm.breakpoint.value !== 'xs')?_c('span',{staticClass:"button small font-weight-bold text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('exhibitor-portal.back-to-event'))+" ")]):_vm._e()],1):_vm._e(),(!_vm.isUnifyExhibitorPortal)?_c('b-link',{staticClass:"main-logo",attrs:{"to":{ path: '/' }}},[(_vm.breakpoint.value === 'md' || _vm.isMobile)?_c('avatar-solo-widget',{staticClass:"ml-3",attrs:{"size":_vm.isMobile ? 40 : 56,"square":true,"src":_vm.logoPath,"entity":"Exhibitor"}}):_vm._e()],1):_vm._e()],1),(_vm.isLargeDevice)?_c('div',{staticClass:"align-items-center d-flex"},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.pageType !== 'sign-up'
            && _vm.featureByKey(_vm.FeatureKeys.GLOBAL_SEARCH)
            && _vm.featureByKey(_vm.FeatureKeys.GLOBAL_SEARCH).enabled)?_c('button-icon-component',{staticClass:"mr-3",attrs:{"size":"md","variant":"alt-1"},on:{"on-click":_vm.toggleSearch},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-magnifying-glass","icon-classes":"text-neutral-n-6-label"}})]},proxy:true}],null,false,916632747)}):_vm._e(),_c('locale-switcher',{staticClass:"mr-3",on:{"on-open-menu":_vm.closeMenuMobile}})],1),(_vm.pageType !== 'sign-up'
          && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_EXTERNAL_REGISTRATION_FEATURE)
          && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_EXTERNAL_REGISTRATION_FEATURE).enabled
          && _vm.community.organizer.registrationWebsite)?_c('button-component',{staticClass:"mr-2 btn-external-registration",attrs:{"text":("" + (_vm.$t('app.login-page.register'))),"size":"md","variant":"secondary"},on:{"on-click":_vm.onRegisterClick}}):(_vm.pageType !== 'sign-up'
          && _vm.featureByKey(_vm.FeatureKeys.USER_SIGNUP)
          && _vm.featureByKey(_vm.FeatureKeys.USER_SIGNUP).enabled)?_c('button-component',{staticClass:"mr-2",attrs:{"text":("" + (_vm.$t('app.login-page.signup'))),"size":"md","variant":"secondary"},on:{"on-click":_vm.onSignupClick}}):_vm._e(),(_vm.pageType !== 'sign-up'
          && _vm.featureByKey(_vm.FeatureKeys.UNIFY_LOGIN)
          && _vm.featureByKey(_vm.FeatureKeys.UNIFY_LOGIN).enabled)?_c('button-component',_vm._b({attrs:{"text":("" + (_vm.$t('app.login-page.sign-in'))),"size":"md","variant":"primary"},on:{"on-click":_vm.onSignInClick}},'button-component',_vm.useTestDataAttribute('login-btn'),false)):_vm._e()],1):_c('div',{staticClass:"d-flex align-items-center column-gap-2"},[(_vm.pageType !== 'sign-up'
          && _vm.featureByKey(_vm.FeatureKeys.GLOBAL_SEARCH)
          && _vm.featureByKey(_vm.FeatureKeys.GLOBAL_SEARCH).enabled)?_c('button-icon-component',{attrs:{"size":"md","variant":"alt-1"},on:{"on-click":_vm.toggleSearch},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-magnifying-glass","icon-classes":"text-neutral-n-6-label"}})]},proxy:true}],null,false,916632747)}):_vm._e(),(_vm.pageType !== 'sign-up')?[(_vm.featureByKey(_vm.FeatureKeys.COMMUNITY_EXTERNAL_REGISTRATION_FEATURE)
            && _vm.featureByKey(_vm.FeatureKeys.COMMUNITY_EXTERNAL_REGISTRATION_FEATURE).enabled
            && _vm.community.organizer.registrationWebsite)?_c('button-component',{staticClass:"btn-external-registration",attrs:{"text":("" + (_vm.$t('app.login-page.register'))),"size":"md","variant":"secondary"},on:{"on-click":_vm.onRegisterClick}}):(_vm.featureByKey(_vm.FeatureKeys.USER_SIGNUP)
            && _vm.featureByKey(_vm.FeatureKeys.USER_SIGNUP).enabled)?_c('button-component',{attrs:{"text":("" + (_vm.$t('app.login-page.signup'))),"size":"md","variant":"secondary"},on:{"on-click":_vm.onSignupClick}}):_vm._e(),(_vm.featureByKey(_vm.FeatureKeys.UNIFY_LOGIN)
            && _vm.featureByKey(_vm.FeatureKeys.UNIFY_LOGIN).enabled)?_c('button-component',{attrs:{"text":("" + (_vm.$t('app.login-page.sign-in'))),"size":"md","variant":"primary"},on:{"on-click":_vm.onSignInClick}}):_vm._e()]:_vm._e()],2)]:_c('global-input-search-component',{attrs:{"default-value":""},on:{"search-query-enter-key":_vm.onSearchEnterKey,"cancel-search":_vm.cancelSearch}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }